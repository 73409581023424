// let xitouBaseURL = 'http://dev.xitou.uama.cc/manage/'
// // 获取页面列表
// const getPageListURL = `${xitouBaseURL}bigView/page/list`
// // 获取面板列表
// const getPanelListURL = `${xitouBaseURL}bigView/panel/list`
// // 获取模块列表
// const getModuleListURL = `${xitouBaseURL}bigView/module/list`
// // 获取搜索内容
// const getSearchDataURL = `${xitouBaseURL}bigView/data/single`
// // 上传图片
// const uploadURL = `${API_CONFIG.uploadURL}?module=bigview`
// // 删除
// const deleteURL = `${xitouBaseURL}bigView/data/delete`
// // 保存数据
// const saveURL = `${xitouBaseURL}bigView/data/save`

// 获取页面列表
const getPageListURL = `${API_CONFIG.butlerBaseURL}bigView/page/list`
// 获取面板列表
const getPanelListURL = `${API_CONFIG.butlerBaseURL}bigView/panel/list`
// 获取模块列表
const getModuleListURL = `${API_CONFIG.butlerBaseURL}bigView/module/list`
// 获取搜索内容
const getSearchDataURL = `${API_CONFIG.butlerBaseURL}bigView/data/single`
// 上传图片
const uploadURL = `${API_CONFIG.uploadURL}?module=bigview`
// 删除
const deleteURL = `${API_CONFIG.butlerBaseURL}bigView/data/delete`
// 保存数据
const saveURL = `${API_CONFIG.butlerBaseURL}bigView/data/save`

export {
  getPageListURL,
  getPanelListURL,
  getModuleListURL,
  getSearchDataURL,
  uploadURL,
  deleteURL,
  saveURL
}
