<template>
  <div class="BigDataConfigList-wrapper">
    <form-panel :hasHeader="false">
      <div class="searchPanel"
           ref="searchPanel">
        <div class="control-list">
          <v-select label="页面"
                    v-model="pageId"
                    :options="pageList"
                    :width="width"
                    @change="pageChange" />
          <v-select label="面板"
                    v-model="panelId"
                    :options="panelOps"
                    :width="width"
                    @change="panelChange" />
          <v-select label="模块"
                    v-model="moduleId"
                    :options="moduleOps"
                    :width="width" />
        </div>
        <div class="button-list">
          <v-button type="success"
                    @click="searchData">搜索</v-button>
          <v-button v-if="isMultiRow !== 0"
                    @click="toAdd">新增</v-button>
        </div>
      </div>
      <div class="list"
           v-if="hasSearched && isMultiRow !== 0">
        <table-panel v-if="headers.length>0"
                     :headers="headers"
                     :tableData="tableData"
                     class="orderTable">
          <template #operateSlot="scope">
            <v-button type="text"
                      text="编辑"
                      @click="toEdit(scope.row)"></v-button>
            <v-button type="text"
                      text="删除"
                      @click="toDelete(scope.row)"></v-button>
          </template>
        </table-panel>
      </div>
      <el-dialog title="编辑"
                 :visible.sync="editDialog"
                 width="800px">
        <div class="form-panel">
          <!-- <el-form-item class="form-item"
                        label="所属类型">
            <v-select v-model="editForm.keyType"
                      :options="keyTypeOps" />
          </el-form-item>
          <el-form-item class="form-item"
                        label="ID">
            <v-input v-model="editForm.keyId" />
          </el-form-item> -->
          <el-form-item class="form-item"
                        label="排序">
            <v-input-number v-model="editForm.sort"
                            controls
                            controls-position="right"
                            :min="0"
                            :max="9999"></v-input-number>
          </el-form-item>
          <!-- <el-form-item class="form-item"
                        label="第二排序">
            <v-input v-model="editForm.sortValue" />
          </el-form-item> -->
          <template v-for="(item, index) in columnList">
            <el-form-item class="form-item"
                          v-if="item.code!='smallTown_valueAndTax_topTen_index'"
                          :key="index"
                          :label="item.name">
              <v-uploader v-if="item.type === 3"
                          :action="uploadURL"
                          :imgUrls.sync="editForm.data[item.code]">
              </v-uploader>
              <span v-else-if="item.type === 1">
                <v-input-number v-model="editForm.data[item.code]"
                                controls
                                controls-position="right">
                </v-input-number>
                <el-tooltip v-if="item.isRealTime"
                            placement="top">
                  <i class="tips-icon el-icon-warning"></i>
                  <div slot="content">
                    <div v-if="item.remark">remark: {{item.remark}}</div>
                    <div>URL: {{item.url}}</div>
                    <div>Path: {{item.path}}</div>
                  </div>
                </el-tooltip>
                <el-tooltip v-else-if="item.remark"
                            :content="item.remark"
                            placement="top">
                  <i class="tips-icon el-icon-info"></i>
                </el-tooltip>
              </span>
              <span v-else-if="5===item.type">
                <v-input v-model="editForm.data[item.code]"
                         :maxlength="150" /> %
                <el-tooltip v-if="item.isRealTime"
                            placement="top">
                  <i class="tips-icon el-icon-warning"></i>
                  <div slot="content">
                    <div v-if="item.remark">remark: {{item.remark}}</div>
                    <div>URL: {{item.url}}</div>
                    <div>Path: {{item.path}}</div>
                  </div>
                </el-tooltip>
                <el-tooltip v-else-if="item.remark"
                            :content="item.remark"
                            placement="top">
                  <i class="tips-icon el-icon-info"></i>
                </el-tooltip>
              </span>
              <span v-else-if="item.type === 6">
                <v-select v-model="editForm.data[item.code]"
                          :options="dictionaries[item.typeRef]" />
                <el-tooltip v-if="item.isRealTime"
                            placement="top">
                  <i class="tips-icon el-icon-warning"></i>
                  <div slot="content">
                    <div v-if="item.remark">remark: {{item.remark}}</div>
                    <div>URL: {{item.url}}</div>
                    <div>Path: {{item.path}}</div>
                  </div>
                </el-tooltip>
                <el-tooltip v-else-if="item.remark"
                            :content="item.remark"
                            placement="top">
                  <i class="tips-icon el-icon-info"></i>
                </el-tooltip>
              </span>
              <span v-else>
                <v-input v-model="editForm.data[item.code]"
                         :maxlength="200" />
                <el-tooltip v-if="item.isRealTime"
                            placement="top">
                  <i class="tips-icon el-icon-warning"></i>
                  <div slot="content">
                    <div v-if="item.remark">remark: {{item.remark}}</div>
                    <div>URL: {{item.url}}</div>
                    <div>Path: {{item.path}}</div>
                  </div>
                </el-tooltip>
                <el-tooltip v-else-if="item.remark"
                            :content="item.remark"
                            placement="top">
                  <i class="tips-icon el-icon-info"></i>
                </el-tooltip>
              </span>
            </el-form-item>
          </template>
        </div>
        <div slot="footer"
             class="dialog-footer">
          <v-button type="default"
                    text="取消"
                    @click="editDialog = false"></v-button>
          <v-button class="confirmbtn"
                    @click="update"
                    text="保存"></v-button>
        </div>
      </el-dialog>
    </form-panel>
    <div class="single-forms-wrapper"
         ref="singleFormsWrapper">
      <div v-if="hasSearched && isMultiRow === 0">
        <form-panel :hasHeader="false">
          <div class="form-panel">
            <template v-for="(item, index) in formColumnList">
              <el-form-item class="form-item"
                            :key="index"
                            :label="editFormData.data[item[0].code]"
                            v-if="item.length>1">
                <div class="form-item-box"
                     v-for="(it, i) in item"
                     :key="i">
                  <v-uploader v-if="it.type === 3"
                              :action="uploadURL"
                              :imgUrls.sync="editFormData.data[it.code]">
                  </v-uploader>
                  <!-- <v-input-number v-else-if="it.type === 1"
                                  v-model="editFormData.data[it.code]">
                  </v-input-number> -->
                  <span v-else-if="5===it.type">
                    <v-input v-model="editFormData.data[it.code]"
                             :maxlength="150" /> %
                    <el-tooltip v-if="it.isRealTime"
                                placement="top">
                      <i class="tips-icon el-icon-warning"></i>
                      <div slot="content">
                        <div v-if="it.remark">remark: {{it.remark}}</div>
                        <div>URL: {{it.url}}</div>
                        <div>Path: {{it.path}}</div>
                      </div>
                    </el-tooltip>
                    <el-tooltip v-else-if="it.remark"
                                :content="it.remark"
                                placement="top">
                      <i class="tips-icon el-icon-info"></i>
                    </el-tooltip>
                  </span>
                  <span v-else-if="it.type === 6">
                    <v-select v-model="editFormData.data[it.code]"
                              :options="dictionaries[it.typeRef]" />
                    <el-tooltip v-if="it.isRealTime"
                                placement="top">
                      <i class="tips-icon el-icon-warning"></i>
                      <div slot="content">
                        <div v-if="it.remark">remark: {{it.remark}}</div>
                        <div>URL: {{it.url}}</div>
                        <div>Path: {{it.path}}</div>
                      </div>
                    </el-tooltip>
                    <el-tooltip v-else-if="it.remark"
                                :content="it.remark"
                                placement="top">
                      <i class="tips-icon el-icon-info"></i>
                    </el-tooltip>
                  </span>
                  <span v-else>
                    <v-input v-model="editFormData.data[it.code]"
                             :maxlength="150" />
                    <el-tooltip v-if="it.isRealTime"
                                placement="top">
                      <i class="tips-icon el-icon-warning"></i>
                      <div slot="content">
                        <div v-if="it.remark">remark: {{it.remark}}</div>
                        <div>URL: {{it.url}}</div>
                        <div>Path: {{it.path}}</div>
                      </div>
                    </el-tooltip>
                    <el-tooltip v-else-if="it.remark"
                                :content="it.remark"
                                placement="top">
                      <i class="tips-icon el-icon-info"></i>
                    </el-tooltip>
                  </span>
                </div>
              </el-form-item>
              <el-form-item class="form-item"
                            :key="index"
                            :label="item[0].name"
                            v-else>
                <div class="form-item-box"
                     v-for="(it, i) in item"
                     :key="i">
                  <v-uploader v-if="it.type === 3"
                              :action="uploadURL"
                              :imgUrls.sync="editFormData.data[it.code]">
                  </v-uploader>
                  <!-- <v-input-number v-else-if="it.type === 1"
                                  v-model="editFormData.data[it.code]">
                  </v-input-number> -->
                  <span v-else-if="5===it.type">
                    <v-input v-model="editFormData.data[it.code]"
                             :maxlength="150" /> %
                    <el-tooltip v-if="it.isRealTime"
                                placement="top">
                      <i class="tips-icon el-icon-warning"></i>
                      <div slot="content">
                        <div v-if="it.remark">remark: {{it.remark}}</div>
                        <div>URL: {{it.url}}</div>
                        <div>Path: {{it.path}}</div>
                      </div>
                    </el-tooltip>
                    <el-tooltip v-else-if="it.remark"
                                :content="it.remark"
                                placement="top">
                      <i class="tips-icon el-icon-info"></i>
                    </el-tooltip>
                  </span>
                  <span v-else-if="it.type === 6">
                    <v-select v-model="editFormData.data[it.code]"
                              :options="dictionaries[it.typeRef]" />
                    <el-tooltip v-if="it.isRealTime"
                                placement="top">
                      <i class="tips-icon el-icon-warning"></i>
                      <div slot="content">
                        <div v-if="it.remark">remark: {{it.remark}}</div>
                        <div>URL: {{it.url}}</div>
                        <div>Path: {{it.path}}</div>
                      </div>
                    </el-tooltip>
                    <el-tooltip v-else-if="it.remark"
                                :content="it.remark"
                                placement="top">
                      <i class="tips-icon el-icon-info"></i>
                    </el-tooltip>
                  </span>
                  <span v-else>
                    <v-input v-model="editFormData.data[it.code]"
                             :maxlength="150" />
                    <el-tooltip v-if="it.isRealTime"
                                placement="top">
                      <i class="tips-icon el-icon-warning"></i>
                      <div slot="content">
                        <div v-if="it.remark">remark: {{it.remark}}</div>
                        <div>URL: {{it.url}}</div>
                        <div>Path: {{it.path}}</div>
                      </div>
                    </el-tooltip>
                    <el-tooltip v-else-if="it.remark"
                                :content="it.remark"
                                placement="top">
                      <i class="tips-icon el-icon-info"></i>
                    </el-tooltip>
                  </span>
                </div>
              </el-form-item>
            </template>
          </div>
          <div class="button-list">
            <v-button type="success"
                      @click="saveFormData">保存</v-button>
          </div>
        </form-panel>
      </div>
    </div>
  </div>
</template>

<script>
import { uploadURL, getPageListURL, getPanelListURL, getModuleListURL, getSearchDataURL, deleteURL, saveURL } from './api'
import { TablePanel } from 'components/bussiness/index'
import { vUploader } from 'components/control'
import { createImgVNode } from 'common/vdom'
import { mapHelper } from 'common/utils'
import _ from 'lodash'
export default {
  name: 'FocusList',
  components: {
    TablePanel,
    vUploader
  },
  data () {
    return {
      pageList: [],
      pageId: undefined,
      currentFormComponent: '',
      hasSearched: false,
      width: 182,
      uploadURL: uploadURL,
      panelOps: [],
      moduleOps: [],
      page: undefined,
      panelId: undefined,
      moduleId: undefined,
      titleModuleId: undefined,
      headers: [],
      tableData: [],
      editDialog: false,
      editFormData: {
        data: {},
        keyId: '',
        keyType: 0,
        sort: undefined,
        sortValue: ''
      },
      editForm: {
        data: {},
        keyId: '',
        keyType: 0,
        sort: undefined,
        sortValue: ''
      },
      columnList: [],
      formColumnList: [],
      editModuleId: undefined,
      isMultiRow: 0,
      hasImg: false,
      imgCode: '',
      isPercent: false,
      percentCode: '',
      dictionaries: {}
    }
  },
  created () {
    // this.pageChange(this.pageId)
    this.getPages()
  },
  methods: {
    getPages () {
      this.$axios.get(getPageListURL).then(res => {
        if (res.status === 100) {
          let list = res.data || []
          let pages = []
          list.forEach(item => {
            pages.push({
              text: item.name,
              value: item.id
            })
          })
          this.pageList = pages
        }
      })
    },
    getPanelOps (pageId) {
      this.$axios.get(`${getPanelListURL}?page=${pageId}&visible=1`).then(res => {
        if (res.status === 100) {
          this.panelOps = res.data.map(item => {
            return {
              text: item.name,
              value: item.id
            }
          })
          this.panelId = undefined
          this.moduleId = undefined
        }
      })
    },
    getModuleOps (panelId) {
      this.$axios.get(`${getModuleListURL}?panelId=${panelId}&visible=1`).then(res => {
        if (res.status === 100) {
          this.moduleOps = res.data.map(item => {
            return {
              text: item.name,
              value: item.id
            }
          })
          this.moduleId = undefined
        }
      })
    },
    pageChange (val) {
      val && this.getPanelOps(val)
    },
    panelChange (val) {
      val && this.getModuleOps(val)
    },
    searchData () {
      let _this = this
      if (!this.moduleId) {
        this.$message.warning('请选择模块')
        return false
      }
      this.hasImg = false
      this.imgCode = ''
      this.isPercent = false
      this.percentCode = ''
      this.$axios.get(`${getSearchDataURL}?moduleId=${this.moduleId}&visible=1`).then(res => {
        if (res.status === 100) {
          let { columns, dataItems, isMultiRow, moduleId, dictionaries } = res.data
          this.headers = columns.map(item => {
            return {
              prop: item.code,
              label: item.name,
              formatter: (row, prop) => {
                if (item.type === 3) {
                  return createImgVNode(this, row, prop, {
                    imgUrl: row.data[_this.imgCode],
                    width: '48px',
                    height: '48px'
                  })
                }
                if (item.type === 5) {
                  return row.data[prop] + '%'
                }
                if (item.type === 6) {
                  const { map: propMap } = mapHelper.setMap(dictionaries[item.typeRef])
                  return propMap[row.data[prop]]
                }
                return row.data[prop]
              }
            }
          })
          columns.forEach(item => {
            if (item.type === 3) {
              _this.imgCode = item.code
              _this.hasImg = true
            }
            if (item.type === 5) {
              _this.percentCode = item.code
            }
          })
          if (_this.hasImg) {
            _this.editForm.data[_this.imgCode] = ''
          }
          dataItems.forEach((it, index) => {
            if (it.data[_this.imgCode] === undefined) {
              it.data[_this.imgCode] = ''
            }
          })
          this.hasSearched = true
          this.columnList = columns
          this.handleColumnList(columns || [])
          this.dictionaries = dictionaries
          this.tableData = dataItems
          dataItems.length && (this.editFormData = dataItems[0])
          this.isMultiRow = isMultiRow
          this.editModuleId = moduleId
          if (this.isMultiRow === 0) {
            let h = document.body.clientHeight
            this.currentFormComponent = 'SingleFormComponent' + moduleId
            this.$refs.singleFormsWrapper.style.height = (h - 300) + 'px'
          }
        }
      })
    },
    handleColumnList (list) {
      let dataObj = {}
      let dataList = []
      let num = 0
      list.forEach(item => {
        if (item.formGroup === 0) {
          num++
          item.formGroup = `group-0-${num}`
        }
        if (!dataObj[item.formGroup]) {
          dataObj[item.formGroup] = [item]
        } else {
          dataObj[item.formGroup].push(item)
        }
      })
      for (let it in dataObj) {
        dataList.push(dataObj[it])
      }
      this.formColumnList = dataList
    },
    toAdd () {
      this.editForm = {
        data: {},
        keyId: '',
        keyType: 0,
        sort: undefined,
        sortValue: ''
      }
      if (this.hasImg) {
        this.editForm.data[this.imgCode] = ''
      }
      this.editDialog = true
    },
    toEdit (data) {
      let _data = _.cloneDeep(data)
      this.editForm = Object.assign({}, _data)
      this.editDialog = true
    },
    async toDelete (data) {
      let result = await this.$confirm('确定要删除吗？')
      if (result) {
        this.$axios.delete(`${deleteURL}/${data.id}`).then(res => {
          if (res.status === 100) {
            this.$message.success('删除成功')
            this.searchData()
          }
        })
      }
    },
    update () {
      delete this.editForm.data['']
      let data = JSON.parse(JSON.stringify({
        dataItems: [this.editForm],
        moduleId: this.editModuleId
      }))
      if (!Object.keys(this.editForm.data).length) {
        return false
      }
      this.$axios.post(saveURL, data).then(res => {
        if (res.status === 100) {
          this.$message.success('保存成功')
          this.editDialog = false
          this.editForm = {
            data: {},
            keyId: '',
            keyType: 0,
            sort: undefined,
            sortValue: ''
          }
          this.searchData()
        }
      })
    },
    saveFormData () {
      let editFormData = this.editFormData
      let data = JSON.parse(JSON.stringify({
        dataItems: [editFormData],
        moduleId: this.editModuleId
      }))
      this.$axios.post(saveURL, data).then(res => {
        if (res.status === 100) {
          this.$message.success('保存成功')
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.searchPanel {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  .control-list {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: 400;
    .v-control {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      min-width: 261px;
      margin-bottom: 20px;
      margin-right: 20px;
    }
  }
  .button-list {
    white-space: nowrap;
    > :not(:last-child) {
      margin-right: 8px;
    }
  }
}
.tips-icon {
  margin-left: 10px;
  color: #409eff;
}
.list {
  display: flex;
  flex: 1;
  overflow-x: auto;
  overflow-y: auto;
}
.list ::v-deep .el-table__body-wrapper {
  max-height: 600px;
  overflow-y: auto;
}
.list ::v-deep .el-table__fixed-body-wrapper {
  max-height: 600px;
  overflow-y: auto;
}
.form-panel {
  display: flex;
  flex-wrap: wrap;
  .form-item {
    width: 380px;
    .form-item-box {
      margin-bottom: 10px;
    }
  }
  .button-list {
    text-align: center;
  }
}
.dialog-footer {
  text-align: right;
  .confirmbtn {
    margin-left: 20px;
  }
}
.single-forms-wrapper {
  overflow: auto;
  .button-list {
    text-align: center;
  }
}
.title-wrapper {
  position: relative;
  .button-list {
    position: absolute;
    left: 293px;
    top: 5px;
  }
}
</style>
