var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "BigDataConfigList-wrapper" },
    [
      _c(
        "form-panel",
        { attrs: { hasHeader: false } },
        [
          _c("div", { ref: "searchPanel", staticClass: "searchPanel" }, [
            _c(
              "div",
              { staticClass: "control-list" },
              [
                _c("v-select", {
                  attrs: {
                    label: "页面",
                    options: _vm.pageList,
                    width: _vm.width,
                  },
                  on: { change: _vm.pageChange },
                  model: {
                    value: _vm.pageId,
                    callback: function ($$v) {
                      _vm.pageId = $$v
                    },
                    expression: "pageId",
                  },
                }),
                _c("v-select", {
                  attrs: {
                    label: "面板",
                    options: _vm.panelOps,
                    width: _vm.width,
                  },
                  on: { change: _vm.panelChange },
                  model: {
                    value: _vm.panelId,
                    callback: function ($$v) {
                      _vm.panelId = $$v
                    },
                    expression: "panelId",
                  },
                }),
                _c("v-select", {
                  attrs: {
                    label: "模块",
                    options: _vm.moduleOps,
                    width: _vm.width,
                  },
                  model: {
                    value: _vm.moduleId,
                    callback: function ($$v) {
                      _vm.moduleId = $$v
                    },
                    expression: "moduleId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "button-list" },
              [
                _c(
                  "v-button",
                  { attrs: { type: "success" }, on: { click: _vm.searchData } },
                  [_vm._v("搜索")]
                ),
                _vm.isMultiRow !== 0
                  ? _c("v-button", { on: { click: _vm.toAdd } }, [
                      _vm._v("新增"),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm.hasSearched && _vm.isMultiRow !== 0
            ? _c(
                "div",
                { staticClass: "list" },
                [
                  _vm.headers.length > 0
                    ? _c("table-panel", {
                        staticClass: "orderTable",
                        attrs: {
                          headers: _vm.headers,
                          tableData: _vm.tableData,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "operateSlot",
                              fn: function (scope) {
                                return [
                                  _c("v-button", {
                                    attrs: { type: "text", text: "编辑" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toEdit(scope.row)
                                      },
                                    },
                                  }),
                                  _c("v-button", {
                                    attrs: { type: "text", text: "删除" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toDelete(scope.row)
                                      },
                                    },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          217010405
                        ),
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-dialog",
            {
              attrs: { title: "编辑", visible: _vm.editDialog, width: "800px" },
              on: {
                "update:visible": function ($event) {
                  _vm.editDialog = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "form-panel" },
                [
                  _c(
                    "el-form-item",
                    { staticClass: "form-item", attrs: { label: "排序" } },
                    [
                      _c("v-input-number", {
                        attrs: {
                          controls: "",
                          "controls-position": "right",
                          min: 0,
                          max: 9999,
                        },
                        model: {
                          value: _vm.editForm.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "sort", $$v)
                          },
                          expression: "editForm.sort",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._l(_vm.columnList, function (item, index) {
                    return [
                      item.code != "smallTown_valueAndTax_topTen_index"
                        ? _c(
                            "el-form-item",
                            {
                              key: index,
                              staticClass: "form-item",
                              attrs: { label: item.name },
                            },
                            [
                              item.type === 3
                                ? _c("v-uploader", {
                                    attrs: {
                                      action: _vm.uploadURL,
                                      imgUrls: _vm.editForm.data[item.code],
                                    },
                                    on: {
                                      "update:imgUrls": function ($event) {
                                        return _vm.$set(
                                          _vm.editForm.data,
                                          item.code,
                                          $event
                                        )
                                      },
                                      "update:img-urls": function ($event) {
                                        return _vm.$set(
                                          _vm.editForm.data,
                                          item.code,
                                          $event
                                        )
                                      },
                                    },
                                  })
                                : item.type === 1
                                ? _c(
                                    "span",
                                    [
                                      _c("v-input-number", {
                                        attrs: {
                                          controls: "",
                                          "controls-position": "right",
                                        },
                                        model: {
                                          value: _vm.editForm.data[item.code],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editForm.data,
                                              item.code,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "editForm.data[item.code]",
                                        },
                                      }),
                                      item.isRealTime
                                        ? _c(
                                            "el-tooltip",
                                            { attrs: { placement: "top" } },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "tips-icon el-icon-warning",
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "content" },
                                                  slot: "content",
                                                },
                                                [
                                                  item.remark
                                                    ? _c("div", [
                                                        _vm._v(
                                                          "remark: " +
                                                            _vm._s(item.remark)
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _c("div", [
                                                    _vm._v(
                                                      "URL: " + _vm._s(item.url)
                                                    ),
                                                  ]),
                                                  _c("div", [
                                                    _vm._v(
                                                      "Path: " +
                                                        _vm._s(item.path)
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          )
                                        : item.remark
                                        ? _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content: item.remark,
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "tips-icon el-icon-info",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : 5 === item.type
                                ? _c(
                                    "span",
                                    [
                                      _c("v-input", {
                                        attrs: { maxlength: 150 },
                                        model: {
                                          value: _vm.editForm.data[item.code],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editForm.data,
                                              item.code,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "editForm.data[item.code]",
                                        },
                                      }),
                                      _vm._v(" %\n              "),
                                      item.isRealTime
                                        ? _c(
                                            "el-tooltip",
                                            { attrs: { placement: "top" } },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "tips-icon el-icon-warning",
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "content" },
                                                  slot: "content",
                                                },
                                                [
                                                  item.remark
                                                    ? _c("div", [
                                                        _vm._v(
                                                          "remark: " +
                                                            _vm._s(item.remark)
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _c("div", [
                                                    _vm._v(
                                                      "URL: " + _vm._s(item.url)
                                                    ),
                                                  ]),
                                                  _c("div", [
                                                    _vm._v(
                                                      "Path: " +
                                                        _vm._s(item.path)
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          )
                                        : item.remark
                                        ? _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content: item.remark,
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "tips-icon el-icon-info",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : item.type === 6
                                ? _c(
                                    "span",
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          options:
                                            _vm.dictionaries[item.typeRef],
                                        },
                                        model: {
                                          value: _vm.editForm.data[item.code],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editForm.data,
                                              item.code,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "editForm.data[item.code]",
                                        },
                                      }),
                                      item.isRealTime
                                        ? _c(
                                            "el-tooltip",
                                            { attrs: { placement: "top" } },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "tips-icon el-icon-warning",
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "content" },
                                                  slot: "content",
                                                },
                                                [
                                                  item.remark
                                                    ? _c("div", [
                                                        _vm._v(
                                                          "remark: " +
                                                            _vm._s(item.remark)
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _c("div", [
                                                    _vm._v(
                                                      "URL: " + _vm._s(item.url)
                                                    ),
                                                  ]),
                                                  _c("div", [
                                                    _vm._v(
                                                      "Path: " +
                                                        _vm._s(item.path)
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          )
                                        : item.remark
                                        ? _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content: item.remark,
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "tips-icon el-icon-info",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _c(
                                    "span",
                                    [
                                      _c("v-input", {
                                        attrs: { maxlength: 200 },
                                        model: {
                                          value: _vm.editForm.data[item.code],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editForm.data,
                                              item.code,
                                              $$v
                                            )
                                          },
                                          expression:
                                            "editForm.data[item.code]",
                                        },
                                      }),
                                      item.isRealTime
                                        ? _c(
                                            "el-tooltip",
                                            { attrs: { placement: "top" } },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "tips-icon el-icon-warning",
                                              }),
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "content" },
                                                  slot: "content",
                                                },
                                                [
                                                  item.remark
                                                    ? _c("div", [
                                                        _vm._v(
                                                          "remark: " +
                                                            _vm._s(item.remark)
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  _c("div", [
                                                    _vm._v(
                                                      "URL: " + _vm._s(item.url)
                                                    ),
                                                  ]),
                                                  _c("div", [
                                                    _vm._v(
                                                      "Path: " +
                                                        _vm._s(item.path)
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          )
                                        : item.remark
                                        ? _c(
                                            "el-tooltip",
                                            {
                                              attrs: {
                                                content: item.remark,
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "tips-icon el-icon-info",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("v-button", {
                    attrs: { type: "default", text: "取消" },
                    on: {
                      click: function ($event) {
                        _vm.editDialog = false
                      },
                    },
                  }),
                  _c("v-button", {
                    staticClass: "confirmbtn",
                    attrs: { text: "保存" },
                    on: { click: _vm.update },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { ref: "singleFormsWrapper", staticClass: "single-forms-wrapper" },
        [
          _vm.hasSearched && _vm.isMultiRow === 0
            ? _c(
                "div",
                [
                  _c("form-panel", { attrs: { hasHeader: false } }, [
                    _c(
                      "div",
                      { staticClass: "form-panel" },
                      [
                        _vm._l(_vm.formColumnList, function (item, index) {
                          return [
                            item.length > 1
                              ? _c(
                                  "el-form-item",
                                  {
                                    key: index,
                                    staticClass: "form-item",
                                    attrs: {
                                      label:
                                        _vm.editFormData.data[item[0].code],
                                    },
                                  },
                                  _vm._l(item, function (it, i) {
                                    return _c(
                                      "div",
                                      { key: i, staticClass: "form-item-box" },
                                      [
                                        it.type === 3
                                          ? _c("v-uploader", {
                                              attrs: {
                                                action: _vm.uploadURL,
                                                imgUrls:
                                                  _vm.editFormData.data[
                                                    it.code
                                                  ],
                                              },
                                              on: {
                                                "update:imgUrls": function (
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    _vm.editFormData.data,
                                                    it.code,
                                                    $event
                                                  )
                                                },
                                                "update:img-urls": function (
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    _vm.editFormData.data,
                                                    it.code,
                                                    $event
                                                  )
                                                },
                                              },
                                            })
                                          : 5 === it.type
                                          ? _c(
                                              "span",
                                              [
                                                _c("v-input", {
                                                  attrs: { maxlength: 150 },
                                                  model: {
                                                    value:
                                                      _vm.editFormData.data[
                                                        it.code
                                                      ],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.editFormData.data,
                                                        it.code,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "editFormData.data[it.code]",
                                                  },
                                                }),
                                                _vm._v(
                                                  " %\n                  "
                                                ),
                                                it.isRealTime
                                                  ? _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "tips-icon el-icon-warning",
                                                        }),
                                                        _c(
                                                          "div",
                                                          {
                                                            attrs: {
                                                              slot: "content",
                                                            },
                                                            slot: "content",
                                                          },
                                                          [
                                                            it.remark
                                                              ? _c("div", [
                                                                  _vm._v(
                                                                    "remark: " +
                                                                      _vm._s(
                                                                        it.remark
                                                                      )
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            _c("div", [
                                                              _vm._v(
                                                                "URL: " +
                                                                  _vm._s(it.url)
                                                              ),
                                                            ]),
                                                            _c("div", [
                                                              _vm._v(
                                                                "Path: " +
                                                                  _vm._s(
                                                                    it.path
                                                                  )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : it.remark
                                                  ? _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          content: it.remark,
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "tips-icon el-icon-info",
                                                        }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : it.type === 6
                                          ? _c(
                                              "span",
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    options:
                                                      _vm.dictionaries[
                                                        it.typeRef
                                                      ],
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.editFormData.data[
                                                        it.code
                                                      ],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.editFormData.data,
                                                        it.code,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "editFormData.data[it.code]",
                                                  },
                                                }),
                                                it.isRealTime
                                                  ? _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "tips-icon el-icon-warning",
                                                        }),
                                                        _c(
                                                          "div",
                                                          {
                                                            attrs: {
                                                              slot: "content",
                                                            },
                                                            slot: "content",
                                                          },
                                                          [
                                                            it.remark
                                                              ? _c("div", [
                                                                  _vm._v(
                                                                    "remark: " +
                                                                      _vm._s(
                                                                        it.remark
                                                                      )
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            _c("div", [
                                                              _vm._v(
                                                                "URL: " +
                                                                  _vm._s(it.url)
                                                              ),
                                                            ]),
                                                            _c("div", [
                                                              _vm._v(
                                                                "Path: " +
                                                                  _vm._s(
                                                                    it.path
                                                                  )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : it.remark
                                                  ? _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          content: it.remark,
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "tips-icon el-icon-info",
                                                        }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "span",
                                              [
                                                _c("v-input", {
                                                  attrs: { maxlength: 150 },
                                                  model: {
                                                    value:
                                                      _vm.editFormData.data[
                                                        it.code
                                                      ],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.editFormData.data,
                                                        it.code,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "editFormData.data[it.code]",
                                                  },
                                                }),
                                                it.isRealTime
                                                  ? _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "tips-icon el-icon-warning",
                                                        }),
                                                        _c(
                                                          "div",
                                                          {
                                                            attrs: {
                                                              slot: "content",
                                                            },
                                                            slot: "content",
                                                          },
                                                          [
                                                            it.remark
                                                              ? _c("div", [
                                                                  _vm._v(
                                                                    "remark: " +
                                                                      _vm._s(
                                                                        it.remark
                                                                      )
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            _c("div", [
                                                              _vm._v(
                                                                "URL: " +
                                                                  _vm._s(it.url)
                                                              ),
                                                            ]),
                                                            _c("div", [
                                                              _vm._v(
                                                                "Path: " +
                                                                  _vm._s(
                                                                    it.path
                                                                  )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : it.remark
                                                  ? _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          content: it.remark,
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "tips-icon el-icon-info",
                                                        }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                              : _c(
                                  "el-form-item",
                                  {
                                    key: index,
                                    staticClass: "form-item",
                                    attrs: { label: item[0].name },
                                  },
                                  _vm._l(item, function (it, i) {
                                    return _c(
                                      "div",
                                      { key: i, staticClass: "form-item-box" },
                                      [
                                        it.type === 3
                                          ? _c("v-uploader", {
                                              attrs: {
                                                action: _vm.uploadURL,
                                                imgUrls:
                                                  _vm.editFormData.data[
                                                    it.code
                                                  ],
                                              },
                                              on: {
                                                "update:imgUrls": function (
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    _vm.editFormData.data,
                                                    it.code,
                                                    $event
                                                  )
                                                },
                                                "update:img-urls": function (
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    _vm.editFormData.data,
                                                    it.code,
                                                    $event
                                                  )
                                                },
                                              },
                                            })
                                          : 5 === it.type
                                          ? _c(
                                              "span",
                                              [
                                                _c("v-input", {
                                                  attrs: { maxlength: 150 },
                                                  model: {
                                                    value:
                                                      _vm.editFormData.data[
                                                        it.code
                                                      ],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.editFormData.data,
                                                        it.code,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "editFormData.data[it.code]",
                                                  },
                                                }),
                                                _vm._v(
                                                  " %\n                  "
                                                ),
                                                it.isRealTime
                                                  ? _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "tips-icon el-icon-warning",
                                                        }),
                                                        _c(
                                                          "div",
                                                          {
                                                            attrs: {
                                                              slot: "content",
                                                            },
                                                            slot: "content",
                                                          },
                                                          [
                                                            it.remark
                                                              ? _c("div", [
                                                                  _vm._v(
                                                                    "remark: " +
                                                                      _vm._s(
                                                                        it.remark
                                                                      )
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            _c("div", [
                                                              _vm._v(
                                                                "URL: " +
                                                                  _vm._s(it.url)
                                                              ),
                                                            ]),
                                                            _c("div", [
                                                              _vm._v(
                                                                "Path: " +
                                                                  _vm._s(
                                                                    it.path
                                                                  )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : it.remark
                                                  ? _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          content: it.remark,
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "tips-icon el-icon-info",
                                                        }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : it.type === 6
                                          ? _c(
                                              "span",
                                              [
                                                _c("v-select", {
                                                  attrs: {
                                                    options:
                                                      _vm.dictionaries[
                                                        it.typeRef
                                                      ],
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.editFormData.data[
                                                        it.code
                                                      ],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.editFormData.data,
                                                        it.code,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "editFormData.data[it.code]",
                                                  },
                                                }),
                                                it.isRealTime
                                                  ? _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "tips-icon el-icon-warning",
                                                        }),
                                                        _c(
                                                          "div",
                                                          {
                                                            attrs: {
                                                              slot: "content",
                                                            },
                                                            slot: "content",
                                                          },
                                                          [
                                                            it.remark
                                                              ? _c("div", [
                                                                  _vm._v(
                                                                    "remark: " +
                                                                      _vm._s(
                                                                        it.remark
                                                                      )
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            _c("div", [
                                                              _vm._v(
                                                                "URL: " +
                                                                  _vm._s(it.url)
                                                              ),
                                                            ]),
                                                            _c("div", [
                                                              _vm._v(
                                                                "Path: " +
                                                                  _vm._s(
                                                                    it.path
                                                                  )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : it.remark
                                                  ? _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          content: it.remark,
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "tips-icon el-icon-info",
                                                        }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "span",
                                              [
                                                _c("v-input", {
                                                  attrs: { maxlength: 150 },
                                                  model: {
                                                    value:
                                                      _vm.editFormData.data[
                                                        it.code
                                                      ],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.editFormData.data,
                                                        it.code,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "editFormData.data[it.code]",
                                                  },
                                                }),
                                                it.isRealTime
                                                  ? _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "tips-icon el-icon-warning",
                                                        }),
                                                        _c(
                                                          "div",
                                                          {
                                                            attrs: {
                                                              slot: "content",
                                                            },
                                                            slot: "content",
                                                          },
                                                          [
                                                            it.remark
                                                              ? _c("div", [
                                                                  _vm._v(
                                                                    "remark: " +
                                                                      _vm._s(
                                                                        it.remark
                                                                      )
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            _c("div", [
                                                              _vm._v(
                                                                "URL: " +
                                                                  _vm._s(it.url)
                                                              ),
                                                            ]),
                                                            _c("div", [
                                                              _vm._v(
                                                                "Path: " +
                                                                  _vm._s(
                                                                    it.path
                                                                  )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : it.remark
                                                  ? _c(
                                                      "el-tooltip",
                                                      {
                                                        attrs: {
                                                          content: it.remark,
                                                          placement: "top",
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "tips-icon el-icon-info",
                                                        }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                ),
                          ]
                        }),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      { staticClass: "button-list" },
                      [
                        _c(
                          "v-button",
                          {
                            attrs: { type: "success" },
                            on: { click: _vm.saveFormData },
                          },
                          [_vm._v("保存")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }